<template>
    <div class="page">
        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container flex-1 flex">
                首钢微燃机项目
            </div>
        </div>
    </div>
</template>

<script>
import Head from "../../components/head.vue";
import Sidebar from "../../components/sidebar.vue";
import breadInfo from "../../components/breadInfo.vue";
export default {
    data() {
        return {};
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() { },
    mounted() {},
    watch: {},
    methods: {}
}

</script>

<style scoped>

</style>
